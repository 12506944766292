export enum LocalStorageKeyEnum {
  KMONG_SESSION_ID = 'kmongSessionId',
  KMONG_IDENTIFY_EVENT_ID_KEY = 'kmongIdentifyEventId',
  HIDE_ENCRYPTED_MESSAGE_DIALOG = 'hideEncryptedMessageDialog',
  CURRENT_SERVICE_TYPE = 'currentServiceType',
  IS_SET_SELLER_VALUE = 'isSetSellerValue',
  ALARM_MSG_TYPE = 'alarmMsgType',
  INVITE_CODE = 'invite_code',
  SEARCH_HISTORY = 'searchHistory',
  SEARCH_HISTORY_DESKTOP = 'searchHistoryDesktop',
  MONEY_PLUS_SEARCH_HISTORY = 'moneyPlusSearchHistory',
  RECENTLY_VIEWED_GIGS = 'recentlyViewedGigs',
  RECENTLY_VIEWED_CATEGORIES = 'recentlyViewedCategories',
  RECENTLY_VIEWED_CATEGORIES_MOBILE = 'recentlyViewedCategoriesMobile',
  CLOSE_CLICK_UP_SEARCH_GUIDE_MODAL = 'closeClickUpSearchGuideModal',
  DISABLE_ENTERPRISE_PROJECT_MENU_TOOLTIP = 'enterpriseProjectMenuTooltip',
  IS_SHOW_RECOMMENDED_TOOLTIP = 'isShowRecommendedTooltip',
  IS_SHOW_GIG_PREVIEW_MODAL_ONBOARDING_MODAL= 'isShowGigPreviewModalOnboardingModal',
  IS_HIDE_BUSINESS_PROFILE_ONBOARDING_MODAL= 'isHideBusinessProfileModalOnboardingModal',
}
