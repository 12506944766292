import api from './api';
import type { OrganizationStatus } from './business';

export type SocialProviderType = 'KAKAO' | 'NAVER' | 'FACEBOOK' | 'GOOGLE' | 'APPLE' | null;

export interface User {
  USERID: number;
  user_id_enc: number;
  email: string;
  funds: number;
  // 프로필작성 여부
  is_authenticated_seller_profile: boolean;
  // 프로필작성 여부
  is_completed_seller_profile: boolean;
  is_exist_gigs: boolean;
  is_mobile_auth: boolean;
  is_online: boolean;
  rating: string;
  thumbnail: string;
  username: string;
  vacation_expire_date?: string | null;
  net_promoter_score_target_type: 'BUYER' | 'SELLER' | null;
  fullname: string | null;
  seller_type: 'PERSONAL' | 'COMPANY' | '';
  // 전문가 인증 여부 (개인 사업자)
  is_authenticated_seller: boolean;
  // 전문가 약관 동의 여부
  is_seller_registration_agreed: boolean;
  social_provider: SocialProviderType;
  organization: Organization | null;
}

interface BusinessUser {
  role_id: number;
  role_type: string;
  role_type_label: string;
}

export interface Organization {
  organization_id: number;
  company_name: string;
  status: OrganizationStatus;
  business_user: BusinessUser;
}

export interface LoginRequest {
  email: string;
  password: string;
  remember: boolean;
  next_page: string;
}

export interface LoginSuccessResponse {
  data: {
    innerHTML: string;
    uri: string;
  };
  meta: {
    status: 1 | 2;
    USERID: number;
    isMobileAuth: boolean;
    mes: string;
  };
}

export type LogInErrors = 'email' | 'password' | 'sweetAlert';
export interface LoginFailResponse {
  meta: {
    status: -1;
    errors: {
      [key in LogInErrors]?: string[];
    };
  };
}

export interface MemberTotal {
  member_total_count: number;
}

export type LoginResponse = LoginSuccessResponse | LoginFailResponse;
export type LogoutResponse = LoginResponse;

export interface UpdatePasswordRequest {
  password: string;
  password_confirm: string;
  token: string;
}

export interface UpdatePasswordResponse {
  isChanged: boolean;
}

const login = (form: LoginRequest) => api.post<LoginResponse>('/modalLogin', form);

const mobileLogin = (form: LoginRequest) => api.post('/api/user/v2/login', form);

const logout = () => api.get<LogoutResponse>('/logout');

const GET_AUTH = '/api/user/v3/me';

const getAuth = async () => {
  const response = await api.get<User>(GET_AUTH);

  if (response.status === 204) {
    throw Error('Not authorized');
  }

  return response.data;
};

const updatePassword = (form: UpdatePasswordRequest) => api.put<UpdatePasswordResponse>('/api/auth/v1/password', form);

interface PostForgotAccountPayload {
  mobile: string;
  realName: string;
}

interface PostForgotAccountResponse {
  message: string;
}

const postForgotAccount = async (payload: PostForgotAccountPayload) => {
  const { data } = await api.post<PostForgotAccountResponse>('/api/auth/v2/forgot/account', payload);

  return data?.message;
};

interface PostForgotPasswordPayload {
  email: string;
}

interface PostForgotPasswordResponse {
  message: string;
}

const postForgotPassword = async (payload: PostForgotPasswordPayload) => {
  const { data } = await api.post<PostForgotPasswordResponse>('/api/auth/v2/forgot/password', payload);

  return data?.message;
};

export const authApiURL = {
  GET_AUTH,
};

export const authApi = {
  login,
  mobileLogin,
  logout,
  getAuth,
  updatePassword,
  postForgotAccount,
  postForgotPassword,
};
