import api from '../api';

export interface GetRecentTaxRequestResponse {
  companyRegistrationNumber: string;
  companyName: string;
  companyRepresentativeName: string;
  companyAddress: string;
  companyType: string;
  companyKind: string;
  companyEmail: string;
  companyManagerName: string;
  companyManagerContact: string;
}

const getRecentTaxRequest = async () => {
  const { data } = await api.get<GetRecentTaxRequestResponse>('/api/next/order-app/order/v1/orders/tax-requests/recent-tax-request');

  return data;
};

export interface GetCompanyRegistrationNumberValidProps {
  companyName: string;
  companyRegistrationNumber: string;
}

export interface PostCompanyRegistrationNumberValidResponse {
  companyName: string;
  companyRegistrationNumber: string;
  taxType: string;
  taxTypeName: string;
  companyCategoryId: number;
  companyCategoryName: string;
}

const postCompanyRegistrationNumberValid = async (props: GetCompanyRegistrationNumberValidProps) => {
  const { data } = await api.post<PostCompanyRegistrationNumberValidResponse>('/api/organization/v1/organizations/business/company-registration-number/valid', props);

  return data;
};

export interface PostOrganizationsBusinessData {
  companyName: string;
  companyRegistrationNumber: string;
  companyCategoryId: number;
  representativeName: string;
  companyType: string;
  companyKind: string;
  companyAddress: string;
  companyScaleId: number;
  taxType: 'TAX_TYPE_TAX' | 'TAX_TYPE_TAX_FREE' | 'TAX_TYPE_PERSONAL_SIMPLED' | 'TAX_TYPE_PERSONAL_SIMPLED_TAX' | 'TAX_TYPE_TAX_NON_PROFIT';
  companyRegistrationFileId: number;
  bankAccountFileId: number;
  department?: string | null;
  jobCategoryId?: number;
  jobGradeId?: number;
  isAgreedPersonalData: boolean;
}

const postOrganizationsBusiness = async (data: PostOrganizationsBusinessData) => api.post<GetRecentTaxRequestResponse>('/api/next/user-app/organization/v1/organizations/business', data);

export enum OrganizationStatus {
  WAITING = 'WAITING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
}

export interface GetOrganizationsBusinessResponse {
  organizationId: number;
  companyName: string;
  status: OrganizationStatus;
  rejectedReason: string | null;
  createdDateTime: string;
}

const getOrganizationsBusiness = async () => {
  const { data } = await api.get<GetOrganizationsBusinessResponse>('/api/next/user-app/organization/v1/organizations/business');

  return data;
};

interface ErrorMessage {
  title: string;
  description?: string;
}

export const businessAccountErrors: Record<string, ErrorMessage> = {
  ALREADY_EXIST_ORGANIZATION_COMPANY_REGISTRATION_NUMBER: {
    title: '이미 등록된 사업자 등록번호예요',
    description: '승인 상태를 확인해 주세요.',
  },
  DOESNT_EXIST_MOBILE_AUTHENTICATION: {
    title: '휴대폰 인증 정보가 존재하지 않아요',
    description: '휴대폰 인증을 다시 진행해 주세요.',
  },
  ALREADY_EXIST_BUSINESS_USER_MOBILE_AUTHENTICATION: {
    title: '이미 기업계정으로 가입된 휴대폰 인증 정보예요',
    description: '휴대폰 인증을 다시 진행해 주세요.',
  },
  REQUEST_ERROR_INCORRECT_REQUEST_PARAMETER: {
    title: '기업명, 사업자 등록번호를 확인해 주세요',
    description: '다시 입력해 주세요.',
  },
  USER_ERROR_HAS_PENALTY: {
    title: '페널티가 부과된 계정이에요',
  },
  ORGANIZATION_ERROR_ALREADY_EXIST_COMPANY_REGISTRATION_NUMBER: {
    title: '이미 등록된 기업계정이에요',
    description: '다시 입력해 주세요.',
  },
  ORGANIZATION_ERROR_ALREADY_REQUESTED_COMPANY_REGISTRATION_NUMBER: {
    title: '승인 대기 중인 사업자등록번호예요',
  },
  COMPANY_REGISTRATION_NUMBER_AUTH_ERROR_AUTHENTICATION_FAILED: {
    title: '사업자 인증에 실패했어요',
  },
};

interface GetOrganizationProfileResponse {
  companyName: string;
  activeUserCount: number;
  isCorporation: boolean;
}

const getOrganizationProfile = async (organizationId: number) => {
  const { data } = await api.get<GetOrganizationProfileResponse>(`/api/next/user-app/organization/v1/organizations/${organizationId}/profile`);

  return data;
};

interface GetOrganizationTaxRequestResponse {
  companyName: string;
  companyRegistrationNumber: string;
  representativeName: string | null;
  companyType: string;
  companyKind: string;
  companyAddress: string | null;
  companyEmail: string | null;
  companyManagerName: string | null;
  companyManagerContact: string | null;
}

const getOrganizationTaxRequest = async (organizationId: number) => {
  const { data } = await api.get<GetOrganizationTaxRequestResponse>(`/api/next/user-app/organization/v1/organizations/${organizationId}/tax-request`);

  return data;
};

export interface PutOrganizationTaxRequestData {
  companyEmail: string | null;
  companyManagerName: string | null;
  companyManagerContact: string | null;
}

const putOrganizationTaxRequest = async (organizationId: number, data: PutOrganizationTaxRequestData) => api.put(`/api/next/user-app/organization/v1/organizations/${organizationId}/tax-request`, data);

interface GetOrganizationUserProfileResponse {
  email: string;
  nickname: string;
  realName: string | null;
  department: string | null;
  jobCategoryId: number | null;
  jobGradeId: number | null;
}

const getOrganizationUserProfile = async () => {
  const { data } = await api.get<GetOrganizationUserProfileResponse>('/api/next/user-app/organization/v1/business-users/profile');

  return data;
};

export interface PutOrganizationUserProfileData {
  department: string | null;
  jobCategoryId: number | null;
  jobGradeId: number | null;
}

const putOrganizationUserProfile = async (data: PutOrganizationUserProfileData) => api.put<GetOrganizationUserProfileResponse>('/api/next/user-app/organization/v1/business-users/profile', data);

interface GetOrganizationsBusinessDetailResponse {
  companyName: string;
  companyRegistrationNumber: string;
  representativeName: string | null;
  companyCategoryId: number;
  companyType: string;
  companyKind: string;
  companyAddress: string | null;
  companyScaleId: number;
  companyEmail: string | null;
  companyManagerName: string | null;
  companyManagerContact: string | null;
  activeUserCount: number;
  isCorporation: boolean;
}

const getOrganizationDetail = async (organizationId: number) => {
  const { data } = await api.get<GetOrganizationsBusinessDetailResponse>(`/api/next/user-app/organization/v1/organizations/${organizationId}`);

  return data;
};

export const businessAccountApis = {
  getRecentTaxRequest,
  postCompanyRegistrationNumberValid,
  postOrganizationsBusiness,
  getOrganizationsBusiness,
  getOrganizationProfile,
  getOrganizationTaxRequest,
  putOrganizationTaxRequest,
  getOrganizationDetail,
  getOrganizationUserProfile,
  putOrganizationUserProfile,
};
